import './page.css';
import React, { useState, useEffect} from 'react';

function Eligibility() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 420);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        window.addEventListener('resize', handleResize);
        
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            {isMobile ? <MobileEligibility/> : <DesktopEligibility />}
        </div>
    );
}

function DesktopEligibility() {
    return (
        <div className='page'>
            <div className='section'>
                <h2>A quick check on eligibility</h2>
                <div className='qa'>
                    <h3>Pre Nursery:</h3>
                    <h3>Children born between <strong>01/06/2021</strong> and <strong>31/05/2022</strong></h3>
                </div>
                <div className='qa'>
                    <h3>Nursery:</h3>
                    <h3>Children born between <strong>01/06/2020</strong> and <strong>31/05/2021</strong></h3>
                </div>
                <div className='qa'>
                    <h3>Junior Kindergarten:</h3>
                    <h3>Children born between <strong>01/06/2019</strong> and <strong>31/05/2020</strong></h3>
                </div>
                <div className='qa'>
                    <h3>Daycare:</h3>
                    <h3>Daycare services available for children aged from <strong>2 years</strong> to <strong>7 years</strong></h3>
                </div>
                <p>*Please call and ask for more details</p>
            </div>
        </div>
    )
}

function MobileEligibility() {
    return (
        <div className='page'>
            <div className='section'>
                <h2>A quick check on eligibility</h2>
                <div className='mobile-qa'>
                    <h3>Pre Nursery:</h3>
                    <p>Children born between <strong>01/06/2021</strong> and <strong>31/05/2022</strong></p>
                </div>
                <div className='mobile-qa'>
                    <h3>Nursery:</h3>
                    <p>Children born between <strong>01/06/2020</strong> and <strong>31/05/2021</strong></p>
                </div>
                <div className='mobile-qa'>
                    <h3>Junior Kindergarten:</h3>
                    <p>Children born between <strong>01/06/2019</strong> and <strong>31/05/2020</strong></p>
                </div>
                <div className='mobile-qa'>
                    <h3>Daycare:</h3>
                    <p>Daycare services available for children aged from <strong>2 years</strong> to <strong>7 years</strong></p>
                </div>
                <p>*Please call and ask for more details</p>
            </div>
        </div>
    )

}

export default Eligibility;