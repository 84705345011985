import './page.css';
import phone from './images/phone.png';
import email from './images/email.png';
import location from './images/location.png';
import locationQR from './images/location_qr.jpeg';
import Banner from './Banner';


function ContactUs() {
    return (
        <div className='page'>
            <div className='section'>
                <h2 style={{textAlign: 'center'}}>Why wait when you can reach out to us</h2>
                <Banner image={locationQR} alt='Contact Us Information' imgWidth='25%' reverse={true} light={true} icon={true}>
                    <div className='icon-container'>
                        <img src={phone} style={{width:'7vh', height:'auto'}} alt="Phone" className='icon'/>
                        <h3 style={{padding: '0px 10px'}}>+91 99980 22678</h3>
                    </div>
                    <div className='icon-container'>
                        <img src={email} alt="Email" style={{width:'7vh', height:'auto'}} className='icon'/>
                        <h3 style={{padding: '0px 10px'}}>smartbunnies2024@gmail.com</h3>
                    </div>
                    <div className='icon-container'>
                        <img src={location} alt="Location" style={{width:'7vh', height:'9vh'}} className='icon'/>
                        <h3 style={{padding: '0px 10px'}}>Suramya Heights, Eklinji Road, Sanand, Gujarat, 382110</h3>
                    </div>
                </Banner>
            </div>
        </div>
    )
}

export default ContactUs;