import './topBar-copy.css';
import logo from './images/smart_bunnies_logo.png'

function TopBar() {
  return (
    // <>
    //   <div className='top-bar'>
    //     <div className='header-container'>
    //       <div className='header-top-bottom-container'>
    //         <div className='header-top-container'>
    //           <h1 className='title'>SMART BUNNIES</h1>
    //         </div>
    //         <div className='header-bottom-container'>
    //           <p className='subtitle'>Where learning and fun join hands</p>
    //         </div>
    //       </div>
    //       <div className='header-logo-container'>
    //         <img src={logo} alt="Logo" className='logo'/>
    //       </div>
    //     </div> 
    //   </div>
    // </>
    <>
      <div className='top-bar'>
        <div className='header-top-bottom-container-test'>
          <h1 className='title'>SMART BUNNIES</h1>
          <p className='subtitle'>Where learning and fun join hands</p>
        </div>
          <img src={logo} alt="Logo" className='logo'/>
      </div>
    </>
  );
}

export default TopBar;
