import './topBar.css';
import './navBar.css';
import TopBar from './TopBar';
import NavBar from './NavBar';

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import Home from './Home';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Eligibility from './Eligibility';

function App() {
    return (
        <>
            <TopBar />
            <Router>
                <NavBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/eligibility" element={<Eligibility />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                </Routes>
            </Router>
        </>
    )
}

export default App;