import React, { useState, useEffect} from 'react';
import './banner.css'; // import the CSS file

function Banner({ image, alt, imgWidth, reverse, light, children }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 420);

  useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 500);
      };

      window.addEventListener('resize', handleResize);
      
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div>
        {isMobile ? <MobileBanner image={image} alt={alt} imgWidth={imgWidth} reverse={reverse} light={light} children={children}/> : <DesktopBanner image={image} alt={alt} imgWidth={imgWidth} reverse={reverse} light={light} children={children}/>}
    </div>
  );
}

function DesktopBanner({image, alt, imgWidth, reverse, light, children}) {
  return (
    // <div className={`banner ${reverse ? 'reverse': ''}`}>
    <div className={`banner ${light ? 'light' : ''} ${reverse ? 'reverse' : ''}`}>
      <img src={image} alt={alt} className='banner-img' style={{width: imgWidth}}/>
      <div className={`banner-text ${reverse ? 'reverse': ''}`}>
        {children}
      </div>
    </div>
  );
}

function MobileBanner({image, alt, imgWidth, reverse, light, children}) {
  return (
    <div className={`mobile-banner ${light ? 'light' : ''} ${reverse ? 'reverse' : ''}`} >
      <img src={image} alt={alt} className='banner-img' style={{width: '50%'}}/>
      <div className={`mobile-banner-text ${reverse ? 'reverse': ''}`}>
        {children}
      </div>
    </div>
  );
}

export default Banner;