import ImageCarousel from './ImageCarousel';
import './page.css';
import './imageCarousel.css';

// import dummy_image3 from './images/hands_on_learning.jpg';
// import dummy_image2 from './images/language_interaction.png';
// import dummy_image4 from './images/social_emotional.jpg';
// import dummy_image1 from './images/trampoline.jpg';

import image1 from './images/Yoga.jpg';
import image2 from './images/coordination.jpg';
import image3 from './images/fine_motor_skills.jpg';
import image4 from './images/hoops.jpg';
import image5 from './images/sensory.jpg';
import image6 from './images/yellow_day.jpg';
import image7 from './images/planting.jpg';
import image8 from './images/collage_cropped.png';

// const dummy_images = [
//     { src: dummy_image1, alt: 'At smart bunnies developing fine and gross motor skills through fun activities is a must' },
//     { src: dummy_image2, alt: 'Learning language by speaking, listening, and iteracting with others allows kids to express themselves' },
//     { src: dummy_image3, alt: 'Learning complicated concepts through visual and interactable results in a strong foundation' },
//     { src: dummy_image4, alt: 'Expressing emotions and learning social skills helps in holistic development' }
// ]

const images = [
    { src: image8, alt: 'June at a glance' },
    { src: image1, alt: 'Creating awareness of Yoga and practicing balancing' },
    { src: image6, alt: 'Learning the color Yellow through real life examples - Tasty corn chaat and lemonade making' },
    { src: image2, alt: 'Developing hand eye coordination' },
    { src: image7, alt: 'Increasing curiosity and learning new words through interactive activities like planting' },
    { src: image3, alt: 'Developing concept of similarity, fine motor skills, and cognitive skills by identifying and sorting colours' },
    { src: image5, alt: 'Developing all around sensory skills' },
    { src: image4, alt: 'Pure fun is great sometimes!' },
]

function Home() {
    return (
        <div className='page'>
            <div className='section'>
                <h2>Make the right choice for your child</h2>
                <p style={{width: '100%', textAlign: 'justify'}}>
                    Kindergarten or preschool years of education are referred to as <strong>Early Years Education</strong> i.e. Education that children
                    obtain during early stages of their childhood. <strong>But wait!</strong> During childhood years, not only do children love to play
                    and have fun, but they also learn a lot through play. Early childhood is considered as a critical period for the 
                    <strong> holistic development</strong> in the life of a child. At <strong>Smart Bunnies</strong>, we believe that children learn best when they
                    are having fun. All our teachers are <strong>ECCE certified</strong> and our curriculum is designed to help children develop their -
                </p>
                <li>
                    <strong>Physical Skills</strong> - Fine and Gross motor skills
                </li>
                <li>
                    <strong>Language Skills</strong> - Speaking, Listening, Reading, Writing
                </li>
                <li>
                    <strong>Cognitive Skills</strong> - Critical Thinking, Problem Solving, Decision Making, Learning foudnational concepts
                </li>
                <li>
                    <strong>Emotional Skills</strong> - Understanding and expressing emotions, Social Skills
                </li>
                <p style={{width: '100%', textAlign: 'justify'}}>
                    Through <strong>fun activities</strong>, <strong>hands-on learning</strong>, and <strong>interactive teaching</strong> we will enable
                    children to develop holistically and be better prepared to face the world head on. 
                </p>
            </div>
            <div className='section'>
                <ImageCarousel images={images} />
            </div>
        </div>
    );
}

export default Home;