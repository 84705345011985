import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './imageCarousel.css';

function ImageCarousel({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToLeftImage = () => {
    setCurrentImageIndex(prevIndex => prevIndex === 0 ? images.length - 1 : prevIndex - 1);
  };

  const goToRightImage = () => {
    setCurrentImageIndex(prevIndex => prevIndex === images.length - 1 ? 0 : prevIndex + 1);
  };

  return (
    <div className='carousel-container'>
        <button className='carousel-button' onClick={goToLeftImage}>
            <FontAwesomeIcon icon={faArrowLeft} style={{color: 'white'}}/>
        </button>
        <div className='image-caption-container'>
            <img className='carousel-img' src={images[currentImageIndex].src} alt="carousel" />
            <div className='caption'>
                <p>{images[currentImageIndex].alt}</p>
            </div>
        </div>
        <button className='carousel-button' onClick={goToRightImage}>
            <FontAwesomeIcon icon={faArrowRight} style={{color: 'white'}}/>
        </button>
    </div>
  );
}

export default ImageCarousel;