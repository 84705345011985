import './page.css';
import profile from './images/Mumma_school.jpeg';
import React from 'react';
import Banner from './Banner';

function AboutUs() {
    return (
        <div className='page'>
            <div className='section'>
                <h2>At Smart Bunnies we believe in: </h2>
                <ul>
                    <li>Learning through play by purposefully designed activities for a child's holistic development</li>
                    <li>Embrace every child's pace of learning</li>
                    <li>Respect every child's individual interest so that learning becomes a joyful experience for a child</li>
                    <li>A teacher student ratio of 1:15</li>
                    <li>Designing curriculum and activities appropriate to children's physical and cognitive development</li>
                    <li>Learning through experimenting</li>
                    <li>Good parent-teacher relationship for transparency</li>
                    <li>Plenty of communication with the children allowing them to understand the world around them and expressing themselves clearly</li>
                    <li>Prepare the children for a formal education</li>
                    {/* <li>Providing a safe and nurturing environment for children to learn and grow</li>
                    <li>Encouraging children to be curious and creative</li>
                    <li>Helping children to develop a love for learning</li>
                    <li>Supporting children in developing social skills</li>
                    <li>Providing children with a strong foundation in literacy and numeracy</li>
                    <li>Encouraging children to be independent and confident</li> */}
                </ul>
            </div>
            <div className='section'>
                <h2>Meet the Team</h2>
                <Banner image={profile} alt='Image of Urvi Iyer' imgWidth='15%' reverse={false} light={false}>
                    <h2 style={{textAlign: 'left'}}><u>Urvi Iyer</u></h2>
                    <ul>
                        <li>Founder and Director of Smart Bunnies</li>
                        <li>Over 10 years of experience in the field of education</li>
                        <li>ECCE Certified</li>
                        <li>Passionate about early childhood education</li>
                        <li>Uses an innovative approach for combining fun and learning</li>
                    </ul>
                </Banner>
                {/* <Banner image={logo} alt='Image of Hari Iyer' reverse={true}>
                    <h2><u>Hari Iyer</u></h2>
                    <ul>
                        <li>Co-Founder and Director of Smart Bunnies</li>
                        <li>Over 10 years of experience in the field of education</li>
                        <li>Master's in Education</li>
                        <li>Passionate about early childhood education</li>
                    </ul>
                </Banner>
                <Banner image={logo} alt='Image of Abhishek Iyer' reverse={false}>
                    <h2><u>Abhishek Iyer</u></h2>
                    <ul>
                        <li>Co-Founder and Director of Smart Bunnies</li>
                        <li>Over 10 years of experience in the field of education</li>
                        <li>Master's in Education</li>
                        <li>Passionate about early childhood education</li>
                    </ul>
                </Banner> */}
            </div>
        </div>
    );
}

export default AboutUs;