import './navBar.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect} from 'react';

function NavBar() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 420);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 500);
        };
    
        window.addEventListener('resize', handleResize);
        
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
        </div>
    );
}

const MobileNavbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <nav>
                <div className='mobile-navbar-container'>
                    <div className='hamburger-button'>
                        <button className={`hamburger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>☰</button>
                    </div>
                    {menuOpen ?
                        <ul className="mobile-navbar-list">
                            <li>
                                <Link to="/" className="link-style">Home</Link>
                            </li>
                            <li>
                                <Link to="/about-us" className="link-style">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact-us" className="link-style">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/eligibility" className="link-style">Eligibility</Link>
                            </li>
                        </ul>
                        :
                        null
                    }
                </div>
            </nav>  
        </>
    );
};


const DesktopNavbar = () => (
    <>
        <nav>
            <ul className="nav-bar">
                <li>
                    <Link to="/" className="nav-bar-container">Home</Link>
                </li>
                <li>
                    <Link to="/about-us" className="nav-bar-container">About Us</Link>
                </li>
                <li>
                    <Link to="/contact-us" className="nav-bar-container">Contact Us</Link>
                </li>
                <li>
                    <Link to="/eligibility" className="nav-bar-container">Eligibility</Link>
                </li>
            </ul>
        </nav>
    </>
);

export default NavBar;